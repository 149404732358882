import React from "react";
import { Link } from "gatsby";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_lp.scss";
import "../styles/pages/_overal-styling.scss";
import graph from "../images/lp/TSD_Meltwater_Graphs_Hero.png";
import hand from "../images/lp/TSD_Reports_Hero.png";
import graph_graphic from "../images/lp/TSD_Report_Graphic.png";
import circle_graph from "../images/lp/TSD_Meltwater_Graphs-1.png";
import square_graph from "../images/lp/TSD_Meltwater_Graphs-2.png";
import report_icon1 from "../images/lp/TSD_Report_Icons-08.svg";
import report_icon2 from "../images/lp/TSD_Report_Icons-09.svg";
import report_icon3 from "../images/lp/TSD_Report_Icons-10.svg";

const FreeSocialMediaListeningReport = () => {
  return (
    <Layout type="marketin-stuck-lp template-lp meltwater-lp">
      <SEO
        title="The Simple Department shares Free Social Media and Competitor Listening Reports"
        description="Free social media audit and listening reports to help brands and companies understand their social media and media reach and impact as well as compare against their competitors."
      />
      <section className="pre-paid-design-hero meltwater-hero">
        <div className="container">
          <div className="wrapper">
            <h1>
              Get Your FREE{" "}
              <span className="red-underline"> Explore Report! </span>
            </h1>
            <h3>
              Check in on your brand, competitors and industry media coverage.
            </h3>
            <p>
              Outperform your competitors by benchmarking your performance
              against their PR, SEO, and social media activities by leveraging
              our all-in-one media intelligence Explore Report.
            </p>
            <div className="image-container">
              <div className="image-wrapper">
                <p>Share of voice by Tweet impressions</p>
                <img src={graph_graphic} className="graphic" />
                <img src={graph} className="graph" />
                <img src={hand} className="hand" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="under-hero lp-section">
        <div className="container">
          <div className="btn-cta-wrapper">
            <Link
              to="#measure_your_results"
              className="lp-btn blue-btn btn-blue-outline "
            >
              Learn More
            </Link>
            <Link
              to="#get_your_report"
              className="lp-btn white-btn-blue-text btn-blue-outline "
            >
              Get Your Report
            </Link>
          </div>
          <div
            className="flexwrapper meltwater-section1"
            id="measure_your_results"
          >
            <div>
              <img src={circle_graph} />
            </div>
            <div className="order1 leftpadding-large">
              <h2>Measure Your Results</h2>
              <p>
                Our Explore Report allows you to conduct Social Performance
                Analysis - get unprecedented insights into your performance
                compared to similar brands — with social competitor insights
                that scan data from podcasts, popular social networking sites,
                and chat applications.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="lp-section meltwater-section2 howwork">
        <div className="container">
          <div className="flexwrapper" style={{ paddingBottom: "50px" }}>
            <div className="order1 rightpadding-large">
              <h2>Build a Strategy Based on Data</h2>
              <p>
                Create Marketing Positioning Strategies with your custom report
                and data that gives you the ability to view your competitor’s
                search and referral traffic, ad spending, website traffic, app
                downloads, as well as other benchmarking metrics.
              </p>
            </div>
            <div>
              <img src={square_graph} />
            </div>
          </div>
        </div>
        <div className="slanted-section slanted-meltwater">
          <div className="container">
            <h2
              style={{
                paddingBottom: "30px",
                color: "white",
                textAlign: "center",
              }}
            >
              The Power Within Your Explore Report
            </h2>
            <div className="card-wrapper">
              <div className="card">
                <img src={report_icon1} />
                <h3>Analyzing the Competitor Landscape</h3>
                <p>
                  Pinpoint the major players in your industry and use your
                  customised report to determine what brands resonate with your
                  target audience, and why.
                </p>
              </div>
              <div className="card">
                <img src={report_icon2} />
                <h3>Learn From Your Competitors</h3>
                <p>
                  By monitoring over 200 billion conversations, your report
                  includes social and media insights to clearly see where you
                  and your competitors are winning through share-of-voice.
                </p>
              </div>
              <div className="card">
                <img src={report_icon3} />
                <h3>Gauge Consumer/Customer Sentiment</h3>
                <p>
                  Your custom Explore Report will highlight how your audience
                  perceives your brand in relation to competitors and the
                  industry - giving you opportunities to strengthen your brand
                  perception.
                </p>
              </div>
            </div>
            <div className="btn-wrapper" style={{ display: "none" }}>
              <Link
                to="#"
                className="btn sqr-btn-white"
                style={{ marginTop: "40px" }}
              >
                SEE SOME EXAMPLES!
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="get_your_report">
        <div className="form-section">
          <div className="container">
            <h2>Get your FREE Customised Explore Report</h2>
            <p>
              We build your report around the actions you need to take or plan
              right now. In order to do this, we need to collect you and your
              competitors' social handles, hashtags, and brand/product keywords.
            </p>
            <div className="typeform-btn-wrapper">
              <a className="lp-btn white-btn-blue-text btn-blue-outline ">
                I neeeed it now!!!
              </a>
            </div>
            <p className="typeform-btn-text">
              This will launch a chat with a fancy form to help get all the info
              we need.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FreeSocialMediaListeningReport;
